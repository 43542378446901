import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, DataDisplayEvent, DataSourceExecutionEvent, DropdownItem, Label, MapPin, Panel } from "@mcleod/components";
import { CityUtil, ModelRow, StringUtil, UrlUtil, getUnauthSettings } from "@mcleod/core";
import { AutogenLayoutTrackWithoutLogin } from "./autogen/AutogenLayoutTrackWithoutLogin";

export class TrackWithoutLogin extends AutogenLayoutTrackWithoutLogin {

    private stops: [];
    private stopsSet = new Set();

    override onLoad() {
        const settings = getUnauthSettings()?.company_settings
        if (settings["enable_unauth_tracking"] != true) {
            this.removeAll();
            CommonDialogs.showError("Unauthenticated tracking is not allowed.");
            return;
        }
        const searchItems = this.textboxSearchType.items as DropdownItem[];
        if (settings["is_brokerage_ltl"] === true) {
            searchItems.push({ value: "CarrierProNum", caption: "Carrier Pro #" });
            searchItems.push({ value: "ShipperPONum", caption: "Shipper PO #" });
        }
        if (settings["is_asset_ltl"] == true)
            searchItems.push({ value: "ProNum", caption: "Pro #" });
        this.mapStops.immediatelyActivateMap = true;

        const urlProp = UrlUtil.getPropsFromUrl(document.location.search)
        if (urlProp != null && StringUtil.isEmptyString(urlProp.id)) {
            if (!StringUtil.isEmptyString(urlProp.search_type)) {
                searchItems.forEach(item => {
                    if (item.value == urlProp.search_type) {
                        this.textboxSearchType.selectedItem = item;
                        return;
                    }
                });
            }
            if (!StringUtil.isEmptyString(urlProp.search_value)) {
                this.textboxTrackingNumber.text = urlProp.search_value;
            }
            if (this.validateTextboxes()) {
                this.mainDataSource.search({ search_type: urlProp.search_type, search_value: urlProp.search_value }).then(response => {
                    this.checkResponse(response)
                    this.tabsetDetails.remove(this.tabMap);
                });
            }
            else {
                this.panelTrackAndTrace.visible = false;
                this.labelNoOrdersFound.visible = false;
            }
        }
        else if (urlProp != null && !StringUtil.isEmptyString(urlProp.id)) {
            this.mainDataSource.search({ token_id: urlProp.id }).then(response => this.checkResponse(response))
        }
        else {
            this.panelTrackAndTrace.visible = false;
            this.labelNoOrdersFound.visible = true;
        }
    }

    /** This is an event handler for the onClick event of buttonTrack.  */
    buttonTrackOnClick(event: ClickEvent) {
        if (this.validateTextboxes()) {
            const searchType = this.textboxSearchType.selectedItem?.value;
            const trackingNum = this.textboxTrackingNumber.text;
            this.mainDataSource.search({ search_type: searchType, search_value: trackingNum }).then(response => {
                this.checkResponse(response);
                this.tabsetDetails.remove(this.tabMap);
            });
        }
    }

    validateTextboxes(): boolean {
        let isValid = true;
        if (!this.textboxSearchType.validateSimple()) {
            this.textboxSearchType.showTooltip("Search Type Required");
            isValid = false;
        }
        if (!this.textboxTrackingNumber.validateSimple()) {
            this.textboxTrackingNumber.showTooltip("Tracking Number Required");
            isValid = false;
        }
        return isValid;
    }

    /** This is an event handler for the onDataDisplay event of textboxSchedArriveEarly.  */
    textboxSchedArriveEarlyOnDataDisplay(event: DataDisplayEvent) {
        const late = event.rowData.data["sched_arrive_late"];
        if (late != null) {
            (event.target as Label).caption = "Scheduled to arrive between"

        }
    }

    /** This is an event handler for the afterExecution event of sourceCustomerOrdersUnauthenticated.  */
    sourceCustomerOrdersUnauthenticatedAfterExecution(event: DataSourceExecutionEvent) {
        if (event.dataSource.data[0]?.data?.error != null) {
            this.removeAll();
            CommonDialogs.showError(event.dataSource.data[0]?.data?.error);
            return;
        }

        const stops = event.dataSource.data[0]?.data?.stops;
        if (stops != null) {
            this.stops = stops;
            const modelRowsStops = [];
            this.stops.forEach(stop => {
                modelRowsStops.push(new ModelRow(this.tableStops.dataSource.url, false, stop));
            });
            this.tableStops.data = modelRowsStops;
            this.tableStops.displayData(null, modelRowsStops, 0);
        }

        const positions = event.dataSource.data[0]?.data?.positions;
        this.setUpMap(positions);
    }

    /** This is an event handler for the onDataDisplay event of labelStopNumber.  */
    labelStopNumberOnDataDisplay(event: DataDisplayEvent) {
        const status = event.rowData.data["status"];
        const statusLabel = (event.target as Label)
        if (status != null && status == "D") {
            statusLabel.caption = null;
            statusLabel.padding = 0;
            statusLabel.imageName = "circleCheck";
            statusLabel.imageColor = "primary";
            statusLabel.imageWidth = 27;
            statusLabel.borderWidth = 0;

        }
        else if (event.rowData.data["actual_arrival"] != null && event.rowData.data["actual_departure"] == null) {
            statusLabel.backgroundColor = "primary";
            statusLabel.color = "primary.reverse"
        }
    }

    /** This is an event handler for the onDataDisplay event of panelLine.  */
    panelLineOnDataDisplay(event: DataDisplayEvent) {
        if (this.stops != null && event.rowData.data["order_sequence"] == this.stops.length) {
            (event.target as Panel).visible = false;
        }
    }

    /** This is an event handler for the onDataDisplay event of labelCityStateZip.  */
    labelCityStateZipOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Label).caption = CityUtil.formatCityStateZip(event.rowData?.get("city_name"), event.rowData?.get("state"), event.rowData?.get("zip_code"));
    }

    private setUpMap(positions: any) {
        if (positions != null) {
            for (let x = positions.length - 1; x > -1; x--) {
                this.addPositionPin(positions[x]);
            }
        }
    }

    private addPositionPin(position: any) {
        const pin = new MapPin(position.latitude, position.longitude);

        if (position.image != null)
            pin.image = position.image;
        else
            pin.image = "map-pin-white-dot-large";

        pin.tooltip = position.descr;
        pin.caption = position.sequence;
        if (position.caption_color != null)
            pin.captionColor = position.caption_color;
        else
            pin.captionColor = "Blue";
        pin.layoutName = null;

        const key = position.latitude + position.longitude;
        const descr = position.descr;
        if (descr != null && descr.startsWith("Stop")) {
            if (!this.stopsSet.has(key)) {
                this.mapStops.addPin(pin);
                this.stopsSet.add(key);
            }
        }
        else {
            pin.clusterable = false;
            this.mapStops.addPin(pin);
        }
    }

    private checkResponse(response: any) {
        if (response == null || response.modelRows.length == 0) {
            this.panelTrackAndTrace.visible = false;
            this.labelNoOrdersFound.visible = true;
        }
        else {
            this.panelTrackAndTrace.visible = true;
            this.labelNoOrdersFound.visible = false;
        }
    }
}

